<template>
    <div class="pages" v-loading="loading">
        <table-height-auto>
            <template slot="before">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="姓名：">
                        <el-input v-model="search.name" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                    </el-form-item>
                    <el-form-item label="村知了账号：">
                        <el-select v-model="search.czlUserId" placeholder="精确搜索"
                            filterable
                            remote
                            reserve-keyword
                            :remote-method="getUserList"
                            :loading="loadingCzl"
                            @change="getList"
                        >
                            <el-option
                                v-for="item in userList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="联系电话：">
                        <el-input v-model="search.mobile" clearable placeholder="精确搜索" style="width: 160px;"></el-input>
                    </el-form-item>
                    <el-form-item label="身份">
                        <el-select v-model="search.type" placeholder="" style="width: 160px;">
                            <el-option value="" label="全部" />
                            <el-option value="1" label="管理员" />
                            <el-option value="2" label="服务者" />
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" size="medium" @click="getList">查询</el-button>
                        <el-button type="primary" size="medium" @click="reset">重置</el-button>
                    </el-form-item>

                </el-form>
            </template>

            <template v-slot:table="row">
                <el-button type="primary" size="medium" @click="edit({})">新增农场管理员</el-button>
                <el-table v-if="row.height" :height="row.heightText" :data="tableData" style="width: 100%">
                    <el-table-column type="index" width="80" label="序号" />
                    <el-table-column prop="name" label="姓名" width="160" />
                    <el-table-column prop="czlPhone" label="村知了账号" width="180" align="center">
                        <template #default="scope">
                            <el-image class="avatar" fit="contain" :src="scope.row.czlAvatarURL || 'http://imgs.cunzhiliao.com/village-cloud-files/img/mine/defaultAvatar.png'"
                                style="width: 30px;height: 30px;" />
                            {{ scope.row.czlPhone }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="联系电话" show-overflow-tooltip width="160" />
                    <el-table-column prop="type" label="身份">
                        <template #default="scope">
                            {{ scope.row.type == 1 ? '管理员' : '服务者' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="serviceLands" label="服务土地" width="240">
                        <template #default="scope">
                            <el-tag type="success" style="margin-right: 6px;max-width: 180px; overflow: hidden;text-overflow: ellipsis" v-for="(item, i) in scope.row.serviceLands" :key="i">{{ item }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="添加时间" />
                    <el-table-column prop="date" label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" @click="edit(scope.row, '编辑')">编辑</el-button>
                            <el-button size="mini" type="danger" @click="del(scope.row, '编辑')">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>

            <template slot="after">
                <div class="paging-row">
                    <el-pagination v-if="total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </template>
        </table-height-auto>
        <el-dialog title="查看" append-to-body style="z-index: 5001;" class="my-dialog" :visible.sync="isVisible"
            width="800px">
            <el-carousel v-if="isVisible" height="430px" :autoplay="false" trigger="click" :initial-index="initialIndex">
                <!-- <el-carousel-item v-for="item in list" :key="item"> -->
                <el-image v-if="showData.type == 1" :src="showData.img" fit="contain" style="width: 760px;height: 430px;" />
                <video v-else-if="showData.type == 2" :src="showData.video" controls="controls"
                    style="width: 760px;height: 430px;">
                    您的浏览器不支持视频播放
                </video>
                <el-empty v-else :image-size="180" description="数据格式有误！" />
                <!-- </el-carousel-item> -->
            </el-carousel>
            <template #footer>
                <el-button type="primary" @click="isVisible = false">确定</el-button>
            </template>
        </el-dialog>
        <addFarmConfig @closeAdd="closeAdd" :addDialogVisible="addDialogVisible" :currentRow="currentRow" />
    </div>
</template>

<script>
import addFarmConfig from "./components/addFarmConfig.vue";
import { URL } from '../../../config'

export default {
    name: 'landConfig',
    components: {
        addFarmConfig,
    },
    data() {
        return {
            search: {
                name: '',
                czlUserId: '',
                mobile: '',
                type: '',
            },
            loading: false,
            loadingCzl: false,
            userList: [],
            isVisible: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            pageSize: 10,
            serviceTypeList: [],
            addDialogVisible: false,
            currentRow: {},
        }
    },
    methods: {
        getUserList(val) {
            this.loadingCzl = true;
            this.axios.get(`${URL.farm.farmSserGetCzlUserList}?phone=${val || ''}`).then(res=>{
                if(res.code === '0'){
                    this.loadingCzl = false;
                    this.userList = res.data.map((v) => {
                        v.label = `${v.phone ? v.phone : ''}`;
                        return v;
                    });
                }else{
                    this.$message.error(res.msg);
                }
            })
        },
        getList() {
            this.axios.post(URL.farm.farmUserPage, { pageNo: this.currentPage, pageSize: this.pageSize, ...this.search }).then(res => {
                if (res.code === '0') {
                    res.data.records.map(res => {
                        res.serviceLands = res.serviceLands ? res.serviceLands.split(',') : [];
                    })
                    this.tableData = res.data.records
                    this.total = res.data.total;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },

        edit(row) {
            this.addDialogVisible = true;
            this.currentRow = row;
        },

        del(row) {
            this.$confirm('删除后对应前端共享农场不可查看该土地，是否确认删除？', '删除提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.delete(`${URL.farm.farmUserDelete}/${row.id}`).then(res => {
                    if (res.code === '0') {
                        this.getList()
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        closeAdd() {
            this.addDialogVisible = false;
            this.getList();
        },

        reset() {
            this.search = {
                name: '',
                czlUserId: '',
                mobile: '',
                type: '',
            }
            this.getList()
        },

        handleSizeChange(val, type) {
            this.pageSize = val;
            this.currentPage = 1;
            this.getList()
        },

        handleCurrentChange(val, type) {
            this.currentPage = val;
            this.getList();
        },

    },
    mounted() {
        this.getList();
    },

}
</script>

<style lang="less">
.pages {
    .search {
        display: flex;
        justify-content: space-between;

    }

}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
</style>
