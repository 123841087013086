<template>
    <div>
        <el-dialog
            :title="`${JSON.stringify(currentRow) == '{}' ? '新增':'编辑'}农场管理员`"
            :visible.sync="visible"
            width="870px"
            :close-on-click-modal="false"
            :append-to-body="true"
            @close="close"
            center
        >
            <el-form :inline="false" :model="formData" class="formData" ref="formData" :rules="rules" label-width="140px" v-loading="loading">

                <el-form-item label="姓名：" prop="name">
                    <el-input v-model="formData.name" style="width:600px" maxlength="8" placeholder="请输入真实姓名" show-word-limit />
                </el-form-item>
                <el-form-item label="村知了账号：" prop="czlUserId" >
                    <!-- <el-input v-model="formData.czlUserId" style="width:600px" maxlength="8" placeholder="请输入村知了账号" /> -->
                    <el-select v-model="formData.czlUserId" placeholder="请输入村知了账号"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="searchUserList"
                        :loading="loadingCzl"
                    >
                        <el-option
                            v-for="item in userList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="联系电话：" prop="mobile" >
                    <el-input v-model="formData.mobile" style="width:600px" maxlength="11" placeholder="请输入联系电话" />
                </el-form-item>

                <el-form-item label="身份：" prop="type">
                    <el-radio-group v-model="formData.type">
                        <el-radio label="1">管理员</el-radio>
                        <el-radio label="2">服务者</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as formRules from '@/assets/js/formRules'

import { URL } from "../../../../config/url.supplier";

export default {
    name: '',
    props: {
		addDialogVisible:{
			type: Boolean,
			default: false
		},
		currentRow:{
			type:Object,
			default: () => {}
		}
	},
    data() {
        return {
            visible: false,
            loading:false,
            loadingCzl: false,
            czlPhone: '',
            formData: {
                name: '',
                czlUserId: '',
                mobile: '',
                type: '',
            },
            formRules,
            rules: {
                mobile: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                    { min: 11, max: 11, message: '联系电话长度必须为11位', trigger: 'blur' }
                ],
                czlUserId: [
                    { required: true, message: '请输入村知了账号', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入真实姓名', trigger: 'blur' },
                ],
                type: [
                    { required: true, message: '请选择', trigger: 'change' }
                ],
            },
            userList: [],

        }
    },
    methods:  {
        close() {
            this.$emit('closeAdd')
        },

        submit() {
            console.log(JSON.stringify(this.currentRow == {}));
            this.$refs.formData.validate((valid) => {
                if(!valid) { return }
                let url = JSON.stringify(this.currentRow) == '{}' ? URL.farm.farmUserSave : URL.farm.farmUserUpdate;
                this.axios[JSON.stringify(this.currentRow) == '{}' ? 'post' : 'put'](url, {...this.formData }).then(res=>{
                    if(res.code === '0'){
                        this.$message({ type: 'success', message: '成功!' });
                        this.close()
                    }else{
                        this.$message.error(res.msg);
                    }
                })
            })
        },
        searchUserList(val) {
            this.loadingCzl = true;
            this.axios.get(`${URL.farm.farmSserGetCzlUserList}?phone=${val || ''}`).then(res=>{
                if(res.code === '0'){
                    this.loadingCzl = false;
                    this.userList = res.data.map((v) => {
                        v.label = `${v.phone ? v.phone : ''} 昵称: ${v.nickName}`;
                        return v;
                    });
                }else{
                    this.$message.error(res.msg);
                }
            })
        },
        getUserList(val) {
            this.loadingCzl = true;
            this.axios.get(`${URL.farm.farmSserGetCzlUserList}?phone=${val || ''}`).then(res=>{
                if(res.code === '0'){
                    this.loadingCzl = false;
                    this.userList = res.data.map((v) => {
                        v.label = `${v.phone ? v.phone : ''} 昵称: ${v.nickName}`;
                        return v;
                    });
                    this.phoneUserList(this.czlPhone)
                }else{
                    this.$message.error(res.msg);
                }
            })
        },
        phoneUserList(val) {
            this.axios.get(`${URL.farm.farmSserGetCzlUserList}?phone=${val || ''}`).then(res=>{
                if(res.code === '0'){
                    let u = res.data[0]
                    u.label = `${u.phone ? u.phone : ''} 昵称: ${u.nickName}`
                    this.userList.push(u)
                }
            })
        },

    },

    watch:{
        currentRow: {
            handler: function(oldV, newV) {
                console.log(oldV, '3333333');
                const { name, czlUserId, mobile, id, czlPhone } = oldV;
                this.czlPhone = czlPhone
                this.formData = {
                    name,
                    czlUserId,
                    mobile,
                    type: oldV.type?.toString(),
                    id
                }
            },
        },
        addDialogVisible: function(val) {
            this.visible = val;
            this.getUserList()
        }
    },

    mounted() {

    }

}
</script>

<style lang="less" scoped>
.formData{
	padding:0 20px;
	h4{
		text-align: center;
	}
	.quillBox{
		width: 600px;
		height: 400px;
		/deep/.ql-formats{
			line-height:0;
		}
		/deep/.ql-toolbar{
			line-height:0;
		}
		/deep/.viewOnly{
			height: 0px;
			overflow: hidden;
			border-top: 0;
			padding: 0px;
		}
	}
	.upload-item-image{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.marginL10{
		margin-left: 10px;
	}
	h4{
		margin:15px 0;
	}
}
.avatar-uploader-quill {
	display: none;
}

.videoBox{
	position: relative;
	/deep/ .el-upload--picture-card {
		width: 100px;
		height: 100px;
	}
	/deep/ .avatar-uploader-icon {
		position: absolute;
		top: 50%;
		left: 11%;
		margin-left: -35px;
		margin-top: -14px;
	}
}

.videoTips {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000;
    z-index: 2009 !important;
}

/deep/ .el-form-item__content {
    min-width: 140px !important;
}

.quillBox {
	/deep/ .ql-container .ql-video {
		height: 220px;
	}
}
</style>
