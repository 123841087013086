/*
*
* 基于 async-validator 的常用校验规则
* >[async-validator](https://github.com/yiminghe/async-validator)
*
* */
// required
export const empty = [

]
// required
export const required = [
  { trigger: 'blur', required: true, message: '不能为空', },
]
export const requiredChange = [
  { trigger: 'change', required: true, message: '不能为空', },
]
// required
var validateCity = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请填写'))
  } else {
    callback()
  }
}

export const shortName = [
  ...required,
  { trigger: 'blur', pattern: /^[\S]{2,10}$/, message: '2-10个字符（由非空字符_组成）' },
]

export const changeRequired = [
  { validator: validateCity, trigger: 'change', required: true }
]
export const regionValue = {
  required: true,
  type: 'array',
  message: '不能为空',
  trigger: 'change'
}
// 登录用户名
export const account = [
  ...required,
  { trigger: 'blur', pattern: /^[\S]{2,20}$/, message: '2-20个字符（由非空字符_组成）' },
]

// 登录密码
export const password = [
  ...required,
  { trigger: 'blur', pattern: /^[a-zA-Z0-9_.?]{6,30}$/, message: '6-30个字符（由字母、数字、_、.、?组成)', },
]
// 4位数字验证码
export const codeNumberFour = [
  ...required,
  { trigger: 'blur', pattern: /^[0-9]{4}$/, message: '4个字符（由数字组成)', },
]
// 6位数字+字母验证码
export const code = [
  ...required,
  { trigger: 'blur', pattern: /^[a-zA-Z0-9]{6}$/, message: '6个字符（由字母、数字组成)', },
]
// 排序 sort
export const sort = [
  ...required,
  { trigger: 'blur', pattern: /^[0-9]{1,3}$/, message: '0 ~ 1000', },
]
// 店铺名
export const name = [
  ...required,
  { trigger: 'blur', pattern: /^[\S][\S\s]{0,20}[\S]$/, message: '1-20个字符（由中、英文、数字、符号组成）' },
]
export const goodsName = [
  ...required,
  { trigger: 'blur', pattern: /^[\S][\S\s]{0,80}[\S]$/, message: '2-80个字符（由中、英文、数字、符号等组成）' },
]
// 分类名称
export const categoryName = [
  { trigger: 'blur', required: true, message: '分类名名不能为空', },
  { trigger: 'blur', pattern: /^[\S][\S\s]{0,20}[\S]$/, message: '1-20个字符' },
]
// 详细地址
export const address = [
  ...required,
  { trigger: 'blur', pattern: /^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z0-9#\-_()\s]){2,20}$/, message: '2-20个字符（由中、英、数字#_-()组成）' },
]
// email
export const email = [
  // ...required,
  { trigger: 'blur', pattern: /^([A-Za-z0-9_+-.])+@([A-Za-z0-9\-.])+\.([A-Za-z]{2,22})$/, message: 'email格式不正确（[xxx.]xxxx@xx.xxx）' },
]
// personName
export const personName = [
  ...required,
  { trigger: 'blur', pattern: /^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z]){2,20}$/, message: '2-20个字符（由中、英文组成）' },
]
// phone
export const phone = [
  ...required,
  { trigger: 'blur', pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确' },
]
// tel
export const tel = [
  ...required,
  { trigger: 'blur', pattern: /^0\d{2,3}-?\d{7,8}$/, message: '座机号码格式不正确' },
]
// phone
export const phoneTel = [
  ...required,
  { trigger: 'blur', pattern: /^(1[3456789]\d{9})|(0\d{2,3}-?\d{7,8})$/, message: '手机号、座机号' },
]
// backNo
export const backNo = [
  ...required,
  { trigger: 'blur', pattern: /^\d+$/, message: '纯数字银行卡号' },
]
// backNo
export const IDNo = [
  ...required,
  { trigger: 'blur', pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '15、18位身份证号' },
]

// 体积、重量校验
export const volumnRule = [
  { trigger: 'blur', pattern: /^([1-9]\d*|0)(?:\.\d{0,3}[1-9])?$/, message: '请输入正数数值，最多支持四位小数' },
]

// 快递模板 邮费
export const postageMoney = [
  {
    trigger: 'blur',
    validator: function (rule, value, callback) {
      if (!value && (value !== 0)) return callback(new Error('邮费不能为空'))
      const judge = value >= 0
      if (!judge) {
        callback(new Error('邮费不能小于0'))
      } else {
        callback()
      }
    }
  }
]

// 快递模板 重量（件数）
export const postageNumber = [
  {
    trigger: 'blur',
    validator: function (rule, value, callback) {
      const judge = value > 0 && value <= 999.99
      if (!judge) {
        callback(new Error('0.01 - 999.99'))
      } else {
        callback()
      }
    }
  }
]

export function codeDefinedRules () {
  const rules = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('验证码不能为空'))
    }
    try {
      if (value.toUpperCase() !== this.code.toUpperCase()) {
        if (!this.codeErrorNum) this.codeErrorNum = 0
        this.codeErrorNum += 1
        if (this.codeErrorNum >= 5) {
          typeof this.refreshCode === 'function' && this.refreshCode()
          this.codeErrorNum = 0
        }
        return callback(new Error(`验证码错误（${this.codeErrorNum}）`))
      } else {
        this.codeErrorNum = 0
        callback()
      }
    } catch (e) {
      return callback(new Error(e))
    }
  }
  return [...code, { trigger: 'blur', validator: rules, }]
}

/**
 * 生成随机验证码
 * @param type  {number}    //验证码类型:1-数字+字母，2-字母，3-数字
 * @param len   {number}    //长度
 */
export const codeArray = [
  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'
]
export function randomCode (type = 1, len = 6) {
  if (type === 1 || type === 2) {
    let code = ''
    const lengthEnd = type === 1 ? codeArray.length : codeArray.length - 10
    for (let i = 0; i < len; i++) {
      code += codeArray[parseInt(Math.random() * lengthEnd)]
    }
    return code
  } else if (type === 3) {
    return parseInt(Math.random() * Math.pow(10, len)).toString()
  }
}

/**
 * 测试随机数
 * @param max {number}  生成的随机数 0 ~ max
 */
function test (max = 1) {
  const o = {}
  for (let i = 0; i < 10000; i++) {
    const num = parseInt(Math.random() * (max + 1))
    if (o[num]) {
      o[num] += 1
    } else {
      o[num] = 1
    }
  }
  let total = 0
  for (const key in o) {
    total += o[key]
  }
}

// 时间戳转为日期格式
export function formatDate (timeStamp) {
  var date = new Date()
  date.setTime(timeStamp * 1000)
  var y = date.getFullYear()
  var m = date.getMonth() + 1
  m = m < 10 ? ('0' + m) : m
  var d = date.getDate()
  d = d < 10 ? ('0' + d) : d
  var h = date.getHours()
  h = h < 10 ? ('0' + h) : h
  var minute = date.getMinutes()
  var second = date.getSeconds()
  minute = minute < 10 ? ('0' + minute) : minute
  second = second < 10 ? ('0' + second) : second
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
}
